<template>
  <div class="event-item">
    <!-- 请选择 -->
    <el-select
      class="event-item-l"
      v-model="relateobj"
      slot="prepend"
      clearable
      :disabled="isDisabled"
      :placeholder="$t('label.select.please')"
      @change="eventChangeB"
      popper-class="select-pull___"
    >
      <el-option
        v-for="item in relatedItem.reItem"
        :key="item.id"
        :label="item.label_name"
        :value="item.prefix"
      >
      </el-option>
    </el-select>
    <div class="event-item-r" @click="checkItem(1)">
      <!-- 搜索 -->
      {{ showRelate.name || defaultName || $t("label.searchs") }}
      <svg aria-hidden="true" class="icon">
        <use href="#icon-search"></use>
      </svg>
    </div>
    <!-- 搜索 -->
    <el-dialog
      :title="$t('label.searchs')"
      width="60%"
      top="5%"
      :visible.sync="showSearchTable"
      :lock-scroll="true"
      :close-on-click-modal="false"
      :modal="true"
      append-to-body
    >
      <div
        :style="{
          height: dialogBodyHeight,
          overflow: 'auto',
          padding: '0 20px',
        }"
      >
        <search-table
          ref="searchTable"
          :fieldId="fieldId"
          :checked="checkEd"
          :relevant-objid="relevantObjId"
          :relevant-objapi="relevantObjApi"
          :relevant-prefix="relevantPrefix"
          @changeSelect="changeSelect"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { GetBrief, getDialigItem } from "./api";
export default {
  props: {
    isMarketActivity: {
      type: Boolean,
      default: false,
    },
    //相关项是否可编辑
    relevent: {
      type: Boolean,
      default: false,
    },
  },
  name: "relaventSelect",
  data() {
    return {
      //是否禁用
      isDisabled: false,
      relateobj: "",
      showRelate: {},
      relatedItem: {}, // 弹窗相关项
      currentItem: -1,
      fieldId: "",
      emailObj: "",
      relevantPrefix: "",
      relevantObjId: "",
      relevantObjApi: "",
      showSearchTable: false,
      checkEd: false,
      showName: {},
      dialogBodyHeight: `${document.body.offsetHeight - 248}px`,
      defaultName: "",
      showRelateId: "",
    };
  },
  mounted() {
    this.getFormAttr();
    if (this.$route.params.id) {
      this.getRelatedItemName();
    }
    //为次组件赋值
    this.$Bus.$on("component-Set-val", this.componentSetValCallback);
  },
  beforeDestroy(){
    this.$Bus.$off("component-Set-val", this.componentSetValCallback);
  },
  watch: {
    relevent: {
      handler(val) {
        this.isDisabled = val;
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    componentSetValCallback(params) {
      this.relateobj = params.objectLabel;
      this.defaultName = params.relateidccname;
      if (params.isDisabled) {
        this.isDisabled = true;
      }
    },
    //获取默认相关项记录的名称
    async getRelatedItemName() {
      let result = await GetBrief({ id: this.$route.params.id });
      this.defaultName = result.data.title[0]
        ? result.data.title[0].fieldValue
        : "";
      this.$Bus.$emit("deliver-relateid", this.$route.params.id);
    },
    eventChangeB(val) {
      this.showRelate = {};
    },
    changeSelect(res) {
      this.showRelateId = res.data.id;
      this.$Bus.$emit("deliver-relateid", res.data.id);
      if (this.currentItem === 0) {
        if (res.length === 0) {
          this.showName = {};
        } else {
          this.showName = {
            id: res.data.id,
            name: res.data.name,
          };
        }
      } else if (this.currentItem === 1) {
        if (res.length === 0) {
          this.showRelate = {};
        } else {
          this.showRelate = {
            id: res.data.id,
            name: res.data.name,
          };
        }
      }
      this.showSearchTable = false;
    },
    getFormAttr() {
      this.getRelatedItem();
    },
    // 事件类型的相关项处理
    getRelatedItem() {
      // 获取相关项
      getDialigItem({ type: "task" }).then((res) => {
        this.relatedItem = {
          naItem: res.data.whoobjInfoList,
          reItem: res.data.relateobjList,
        };
        //有潜在客户和联系人时添加到相关项中
        if (res.data.whoobjInfoList) {
          res.data.whoobjInfoList.forEach((item) => {
            this.relatedItem.reItem.push(item);
          });
        }
        let objName = this.relatedItem.reItem.filter((res) => {
          return res.labelName == sessionStorage.getItem("tabName");
        });
        if (this.isMarketActivity == false) {
          this.whoobj = this.relatedItem.naItem[0].prefix;
          if (objName.length != 0) {
            this.relateobj = objName[0].prefix;
          } else {
            this.relateobj = this.relatedItem.reItem[0].prefix;
          }
          if (this.emailObj != "") {
            this.relateobj = this.emailObj;
          }
        } else {
          this.whoobj = this.relatedItem.naItem[0].prefix;
          let findIndex = this.relatedItem.reItem.findIndex(
            (res) => res.schemetableName == "campaign"
          );
          this.relateobj = this.relatedItem.reItem[findIndex].prefix;
          if (this.emailObj != "") {
            this.relateobj = this.emailObj;
          }
        }
      });
    },
    // 事件任务类型相关项选择
    checkItem(index) {
      //是否禁用
      if (this.isDisabled) {
        return;
      }
      this.currentItem = index;
      if (index == 1) {
        if (this.relateobj) {
          this.fieldId = "ffe2014000032890C8YD";
          this.relevantPrefix = this.relateobj;
          this.relatedItem.reItem.map((item) => {
            if (item.prefix == this.relateobj) {
              this.relevantObjId = item.id;
              this.relevantObjApi = item.schemetableName;
            }
          });
          this.showSearchTable = true;
          this.$nextTick(() => {
            this.$refs.searchTable.init();
          });
        } else {
          //请先选择相关项
          this.$message.warning(
            this.$i18n.t("vue_label_commonobjects_detail_select_first")
          );
        }
      }
    },
  },
  components: {},
};
</script>

<style lang="scss" scoped>
::v-deep .el-scrollbar__wrap {
  max-width: 400px;
}
::v-deep .select-pull___ {
  max-width: 300px;
}
::v-deep .el-select-dropdown__list {
  max-width: 300px;
}
.el-select-dropdown__wrap {
  max-width: 300px;
}
.event-item {
  display: flex;
  align-items: center;
  ::v-deep .el-select .el-input__inner:focus {
    border-color: #e3e2e2;
  }
  ::v-deep .el-input__icon {
    line-height: normal;
  }
  ::v-deep .el-input__inner {
    border-radius: 4px 0 0 4px;
    border-right: none;
  }
  .event-item-l {
    width: 150px;
    ::v-deep .el-input__inner {
      height: 30px !important;
    }
  }
  .event-item-r {
    position: relative;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    background-color: #fff;
    background-image: none;
    border-radius: 0 4px 4px 0;
    border: 1px solid #e3e2e2;
    box-sizing: border-box;
    color: #666;
    font-size: inherit;
    height: 30px;
    line-height: 40px;
    outline: 0;
    padding: 0 15px;
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-left: none;
    img {
      width: 16px;
      height: 16px;
      position: absolute;
      right: 5px;
      top: 5px;
    }
  }
}
</style>
