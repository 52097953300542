import axios from '@/config/httpConfig'

// 邮件发送页接口
export function getEmailObjectGetEmailPageInfo(data) {
    return axios.post('/emailObject/getEmailPageInfo', data)
}
// 更改邮件追踪状态
export function getEmailObjectGetChangeEmailTrackFlago(data) {
    return axios.post('/emailObject/changeEmailTrackFlag', data)
}
// 获取详情页标签
export function GetBrief(data) {
    return axios.post('/objectdetail/getBrief', data)
}
// 查询邮件模板
export function getEmailObjectGetEmailTemplates(data) {
    return axios.post('/emailObject/getEmailTemplates', data)
}

// 单条邮件详情
export function getEmailObjectgetEmailDetailInfo(data) {
    return axios.post('/emailObject/getEmailDetailInfo', data)
}

// 相关记录下的邮件信息
export function getEmailObjectGetEmailRelateData(data) {
    return axios.post('/emailObject/getEmailRelateData', data)
}

// 根据模板id和记录id获取模板内容
export function getEmailObjectGetTemplateBody(data) {
    return axios.post('/emailObject/getTemplateBody', data)
}

// 查询邀约
export function getEmailObjectgetAppointments(data) {
    return axios.post('/emailObject/getAppointments', data)
}

// 发送电子邮件
export function getEmailObjectsendEmailObject(data) {
    return axios.post('/emailObject/sendEmailObject', data)
}

// 保存邮件模板
export function getEmailObjectsaveEmailTemplate(data) {
    return axios.post('/emailObject/saveEmailTemplate', data)
}

// 置顶模板
export function getEmailObjecttopEmailTemplate(data) {
    return axios.post('/emailObject/topEmailTemplate', data)
}

// 查询字段
export function getEmailObjectqueryFields(data) {
    return axios.post('/emailObject/queryFields', data)
}

// 插入字段
export function getEmailObjectmergeField(data) {
    return axios.post('/emailObject/mergeField', data)
}

// 插入字段
export function getObjectGetObjectList(data) {
    return axios.post('/object/getObjectList', data)
}

// 删除邮件
export function getEmailObjectDelete(data) {
    return axios.post('/emailObject/delete', data)
}

// 根据关键字搜索邮箱
export function getEmailObjectSearchEmail(data) {
    return axios.post('/emailObject/searchEmail', data)
}

// 保存邮件签名
export function getEmailObjectSaveSign(data) {
    return axios.post('/emailObject/saveSign', data)
}


// 查询邮件签名
export function getEmailObjectQuerySign(data) {
    return axios.post('/emailObject/querySign', data)
}
// 清空邮件
export function deleteEmail(data) {
    return axios.post('/emailObject/deleteEmail', data)
}

// 根据发票的id获取邮件所需信息
export function getMessageByNum(data) {
    return axios.post('/emailonlinepay/GetMessageByNum', data)
}

// 邮件已发送
export function emailSuccess(data) {
    return axios.post('/emailonlinepay/emailSuccess', data)
}

// 保存邮件短信模板文件夹
export function saveTemplateFolder(data) {
    return axios.post("/templateFolder/saveTemplateFolder", data);
}
// 查询邮件短信模板文件夹接口
export function queryTemplateFolder(data) {
    return axios.post("/templateFolder/queryTemplateFolder", data);
}
// 删除短信邮件模板文件夹
export function deleteTemplateFolder(data) {
    return axios.post("/templateFolder/deleteTemplateFolder", data);
}
// 保存邮件短信模板
export function saveTemplate(data) {
    return axios.post("/template/saveTemplate", data);
}
// 查询文件夹内模板
export function queryTemplateByFolder(data) {
    return axios.post("/template/queryTemplateByFolder", data);
}
// 删除邮件短信模板
export function deleteTemplate(data) {
    return axios.post("/template/deleteTemplate", data);
}
// 保存邮件短信模板最近查看
export function saveRecentTemplate(data) {
    return axios.post("/template/saveRecentTemplate", data);
}
// 获取邮件短信模板最近查看
export function getRecentTemplate(data) {
    return axios.post("/template/getRecentTemplate", data);
}
// 邮件模板保存图片
export function uploadMailPic(data) {
    return axios.post("/template/uploadMailPic", data);
}

// 专用模板文件夹排序接口
export function sortPrivateTemplate(data) {
    return axios.post("/template/sortPrivateTemplate", data);
}
// 根据名称查询邮件模板
export function queryTemplateByName(data) {
    return axios.post("/template/queryTemplateByName", data);
}
// 根据id获取邮件短信模板
export function queryTemplateById(data) {
    return axios.post("/template/queryTemplateById", data);
}
// 根据id获取文件夹
export function queryTemplateFolderById(data) {
    return axios.post("/templateFolder/queryTemplateFolderById", data);
}




//邮件发送页
export function getEmailPageInfo(data) {
    return axios.post('emailObject/getEmailPageInfo', data)
}

//获取邮件模板
export function getEmailTemplate(onlyShowMyTemplate, searchWord) {
    return axios.post('emailObject/getEmailTemplates', { onlyShowMyTemplate: onlyShowMyTemplate, searchWord: searchWord })
}

//置顶模板
export function toTopTemplate(options) {
    return axios.post('emailObject/topEmailTemplate', options)
}

//保存邮件模板
export function saveEmailTemplate(options) {
    return axios.post('emailObject/saveEmailTemplate', options)
}
//发送邮件
export function sendemail(data) {
    return axios.post('emailObject/sendEmailObject', data)
}

//获取对象列表
export function getobjectlist() {
    return axios.post('object/getAllObjectList')
}

//获取字段
export function getsize(data) {
    return axios.post('emailObject/queryFields', data)
}

//插入字段
export function insertFields(data) {
    return axios.post('emailObject/mergeField', data)
}

//收件箱
export function inbox(data) {
    return axios.post('emailObject/queryEmailList', data)
}

//邮件详情
export function emaildetail(data) {
    return axios.post('emailObject/detail', data)
}

//更改邮件状态
export function changeread(data) {
    return axios.post('emailObject/changeRead', data)
}

//删除邮件
export function deleteemail(data) {
    return axios.post('emailObject/delete', data)
}
//根据模板id和记录获取模板内容获取
export function getTemplateSubject(data) {
    return axios.post('emailObject/getTemplateBody', data)
}

//获取收件人信息
export function getReceivePeo(data) {
    return axios.post('emailObject/getShouJianRenData', data)
}

//搜索收件人
export function seachemail(data) {
    return axios.post('emailObject/searchEmail', data)
}

//上传文件
export function uploadfile(data) {
    return axios.post('file/upload', data)
}

// 查询邮件签名
export function queryEmailSign(data) {
    return axios.post('/emailObject/querySign', data)
}

// 保存邮件签名
export function saveEmailSign(data) {
    return axios.post('/emailObject/saveSign', data)
}

//更改邮件关联记录
export function changerelateid(data) {
    return axios.post('emailObject/changeRelateid', data)
}

//删除邮件关联记录
export function deleterelateid(data) {
    return axios.post('emailObject/deleteRelateid', data)
}

//获取邮件数量
export function getemailnum(data) {
    return axios.post('emailObject/queryEmailCount', data)
}

// 获取新建事件相关项
export function getDialigItem(data) {
    return axios.post('activity/getQuickActivityPage', data)
}